import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CardMedia from "@material-ui/core/CardMedia";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

// import LOGO from "../../assets/images/codeyoung_logo.png";
import EPICENTERLOGO from "../../assets/images/EpicenterWhite.png";

import "./SideNavbar.scss";
import { Button } from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { decrypt } from "../../utils/others";
import { getPermission } from "../../utils/auth";

const drawerWidth = 235;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    height: "100vh",
  },
  drawerPaper: {
    width: "16.5%",
    backgroundColor: "#ff702d",
    color: "#ffffff",
    display: "flex",
    justifyContent: "space-between",
  },
  //   list: {
  //     marginTop: "10rem",
  //   },
  listItems: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "#ffffff",
  },
  activeClassName: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#ffffff",
    color: "#ff702d",
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      background: "none",
    },
  },
  button: {
    backgroundColor: "#ffe500",
  },
}));

const SideNavbar = () => {
  const classes = useStyles();
  const location = useLocation();
  const roles = decrypt(localStorage.getItem("user"));
  const Permission = getPermission();

  const logOut = () => {
    swal({
      title: "Logout",
      text: "Do you really want to logout?",
      buttons: ["No", "Yes, Logout"],
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then((logout) => {
      if (logout) {
        localStorage.removeItem("user");
        window.location.replace("/");
      }
    });
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar}>
        <CardMedia
          className={classes.media}
          // image={EPICENTERLOGO}
          title="Epicenter Logo"
          style={{ height: "4rem" }}
        >
          <img
            src={EPICENTERLOGO}
            alt="Epicenter Logo"
            width="200px"
            height="55px"
            className="py-1 pl-1"
          />
        </CardMedia>
        <Divider />
      </div>
      <List className={classes.list}>
        {[
          {
            text: "Dashboard",
            to: "/mentorDashboard",
            allow: roles.role === "mentor" ? true : false,
          },
          {
            text: "Demos",
            to: "/demos",
            allow: roles.role !== "affiliate_partner" ? true : false,
          },
          {
            text: "Affiliate Demos",
            to: "/affiliateDemos",
            allow: roles.role === "affiliate_partner" ? true : false,
          },
          {
            text: "Classes",
            to: "/classes",
            allow: roles.role !== "affiliate_partner" ? true : false,
          },
          {
            text: "Time table",
            to: "/timetable",
            allow: roles.role === "mentor" ? true : false,
          },
          {
            text: "Profile",
            to: "/mentors",
            allow: roles.role === "mentor" ? true : false,
          },
          {
            text: "Users",
            to: "/users",
            allow: roles.role === "admin" ? true : false,
          },
          {
            text: "Courses",
            to: "/courses",
            allow: roles.role === "admin" ? true : false,
          },
          {
            text: "Promotions",
            to: "/promotions",
            allow: Permission.includes("Promotions") ? true : false,
          },
          {
            text: "Bootcamp",
            to: "/bootcamp",
            allow: Permission.includes("bootCamp") ? true : false,
          },
          {
            text: "Alchemy",
            to: "/alchemy",
            allow: roles.role === "admin" ? true : false,
          },
          {
            text: "Community",
            to: "/community",
            allow: roles.role !== "affiliate_partner" ? true : false,
          },
          {
            text: "Storefront",
            to: "/storefront",
            allow: roles.role === "admin" ? true : false,
          },
        ].map((links, index) => (
          <>
            {links.allow && (
              <NavLink to={links.to} className={classes.link}>
                <ListItem
                  //   button
                  key={links.text}
                  className={
                    location.pathname.includes(links.to)
                      ? classes.activeClassName
                      : classes.listItems
                  }
                >
                  <ListItemText
                    primary={links.text}
                    // className={classes.listItemText}
                  />
                </ListItem>
              </NavLink>
            )}
          </>
        ))}
      </List>
      <Button
        variant="contained"
        startIcon={<ExitToAppIcon />}
        className={classes.button}
        onClick={logOut}
      >
        Logout
      </Button>
      {/* <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
    </Drawer>
  );
};

export default SideNavbar;
