import React, { useState } from "react";
import "./SideNavbar.scss";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import EPICENTERLOGO from "../../assets/images/epicenter_logo.png";
import studentSidebaricon from "../../assets/images/studentSidebaricon.png";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import DvrOutlinedIcon from "@material-ui/icons/DvrOutlined";
import CastForEducationOutlinedIcon from "@material-ui/icons/CastForEducationOutlined";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
//import PhotoCameraFrontOutlinedIcon from '@material-ui/icons/PhotoCameraFrontOutlined';
//import GroupsOutlinedIcon from '@material-ui/icons/GroupsOutlined';
import PeopleOutlinedIcon from "@material-ui/icons/PeopleOutlined";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { decrypt } from "../../utils/others";
import { reducer_clearBreadCrumbs } from "../../Redux/Actions/folderPath.action";
import { useLocation, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { useSelector, useDispatch } from "react-redux";
import TagManager from "react-gtm-module";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import sandbox_logo from "../../assets/images/sandbox_logo.png";
import { API } from "../../Services/api-services";
import Cookies from "js-cookie";
import AuthErrorModal from "./AuthErrorModal";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import FaceIcon from '@material-ui/icons/Face';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "hidden",
    backgroundColor: "#FFF8F5",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    backgroundColor: "#FFF8F5",
  },
}));

export default function SideNavigationBar() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const path = useSelector((state) => state.folderPathReducer.folderPath);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const roles = decrypt(localStorage.getItem("user"));
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const logOut = () => {
    swal({
      title: "Logout",
      text: "Do you really want to logout?",
      buttons: ["No", "Yes, Logout"],
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then((logout) => {
      if (logout) {
        localStorage.removeItem("user");
        window.location.replace("/");
      }
    });
  };
  const sandBoxLogin = () => {
    setLoader(true);
    API.get(`/commonAuth/token`)
      .then((res) => {
        if (res.data.token) {
          setLoader(false);
          Cookies.set("AuthorizationToken", res.data.token, {
            path: "/",
            domain: ".codeyoung.com",
          });
          window.open(`${process.env.REACT_APP_SANDBOX_URL}`, "_blank");
        }
      })
      .catch((e) => {
        console.error("Error while creating auth token", e);
      });
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const closeErrorModal = () => {
    setLoader(false);
  };

  const navigateTo = (to) => {
    if (path.length > 0) {
      dispatch(reducer_clearBreadCrumbs());
    }
    const action =
      to === "/demos"
        ? "demos_clicked"
        : to === "/classes"
        ? "classes_opened"
        : to === "/timetable"
        ? "timetable_opened"
        : to === "/mentorResources"
        ? "resources_opened"
        : to === "/community"
        ? "community_clicked"
        : to === "/mentors"
        ? "mentors_clicked"
        : to === "/mentorDashboard"
        ? "dashboard_loaded"
        : "/";
    const tagManagerArgs = {
      gtmId: "GTM-TH4RWGG",
      dataLayer: {
        event: "codeyoung_epicenter",
        event_category: "codeyoung_epicenter",
        event_action: action,
        event_label: roles.user_name,
        event_label1: "",
        event_label2: "",
        event_label3: "",
        // event_label1: mentorProfileDetails.email,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    TagManager.initialize(tagManagerArgs);
    history.push(to);
  };

  return (
    <>
      <div className={classes.root}>
        {loader && (
          <AuthErrorModal open={loader} closeModals={closeErrorModal} />
        )}
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          onMouseEnter={handleDrawerOpen}
          onMouseLeave={handleDrawerClose}
        >
          <List>
            <ListItem style={{ paddingLeft: "0px" }}>
              <ListItemIcon>
                <img
                  src={EPICENTERLOGO}
                  alt="epicenter"
                  style={{ marginLeft: "2%", height: "50px", width: "230px" }}
                />
              </ListItemIcon>
            </ListItem>
          </List>
          <Divider />
          <div style={{ marginTop: 10 }}>
            <List>
              {[
                {
                  text: "Dashboard",
                  to: "/mentorDashboard",
                  allow: roles.role === "mentor" ? true : false,
                  icon: DashboardOutlinedIcon,
                },
                {
                  text: "Demos",
                  to: "/demos",
                  allow: roles.role !== "affiliate_partner" ? true : false,
                  icon: DvrOutlinedIcon,
                },

                {
                  text: "Classes",
                  to: "/classes",
                  allow: roles.role !== "affiliate_partner" ? true : false,
                  icon: CastForEducationOutlinedIcon,
                },
                {
                  text: "Resources",
                  to: "/mentorResources",
                  allow: roles.role === "mentor" ? true : false,
                  icon: LibraryBooksIcon,
                },
                {
                  text: "Students",
                  to: "/student-manage",
                  allow: roles.role === "mentor" ? true : false,
                  icon: FaceIcon,
                },
                {
                  text: "Time table",
                  to: "/timetable",
                  allow: roles.role === "mentor" ? true : false,
                  icon: TableChartOutlinedIcon,
                },
                {
                  text: "Profile",
                  to: "/mentors",
                  allow: roles.role === "mentor" ? true : false,
                  icon: AssignmentIndOutlinedIcon,
                },

                {
                  text: "Community",
                  to: "/community",
                  allow: roles.role !== "affiliate_partner" ? true : false,
                  icon: PeopleOutlinedIcon,
                },
              ].map((route, index) => {
                if (roles.role == "mentor") {
                  return (
                    <>
                      <ListItem
                        button
                        key={route.text}
                        onClick={() => navigateTo(route.to)}
                        style={{
                          color: location.pathname.includes(route.to)
                            ? "#F66C28"
                            : "#BCA987",
                          backgroundColor: location.pathname.includes(route.to)
                            ? "#FFF8F5"
                            : "",
                          paddingLeft: 18,
                          marginBottom: 10,
                          paddingTop: matches ? 0 : 10,
                        }}
                      >
                        <ListItemIcon>
                          <route.icon
                            // width="48"
                            style={{
                              color: location.pathname.includes(route.to)
                                ? "#F66C28"
                                : "#BCA987",
                              fontSize: "30px",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          style={{ fontSize: "21px" }}
                          primary={route.text}
                        />
                      </ListItem>
                    </>
                  );
                }
              })}
            </List>
          </div>
          <ListItem
            button
            key="sandboxLogin"
            onClick={sandBoxLogin}
            style={{
              color: "#BCA987",
              paddingLeft: 10,
              marginTop: -10,
            }}
          >
            <ListItemIcon>
              <img
                src={sandbox_logo}
                style={{
                  fontSize: "45px",
                }}
              />
            </ListItemIcon>
            <ListItemText
              style={{ fontSize: "21px", paddingLeft: 6 }}
              primary="Sandbox"
            />
          </ListItem>
          <ListItem
            button
            key="Logout"
            onClick={logOut}
            style={{
              color: location.pathname.includes("/") ? "#FF3333" : "",
              backgroundColor: location.pathname.includes("/") ? "#FFD6D6" : "",
              paddingLeft: 18,
              marginTop: 19,
            }}
          >
            <ListItemIcon>
              <PowerSettingsNewIcon
                // width="48"
                style={{
                  color: location.pathname.includes("/") ? "#FF3333" : "",
                  fontSize: "30px",
                }}
              />
            </ListItemIcon>
            <ListItemText style={{ fontSize: "21px" }} primary="Logout" />
          </ListItem>
        </Drawer>
      </div>
    </>
  );
}
